import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { CampaignRamadhanPresenter } from '../presenters/CampaignRamadhanPresenter'
import { CampaignRamadhanRequest } from '@/data/payload/api/CampaignRamadhanRequest'
import { Validation, PackageType } from '@/domain/entities/CampaignRamadhan'

export interface CampaignRamadhanState {
  isLoading: boolean
  validateData: Validation
  orderId: number
}

@Module({ namespaced: true, store, name: 'campaign-ramadhan', dynamic: true })
class CampaignRamadhanController extends VuexModule
  implements CampaignRamadhanState {
  private presenter: CampaignRamadhanPresenter = container.resolve(
    CampaignRamadhanPresenter
  )

  public isLoading = false
  public validateData = new Validation()
  public orderId = 0

  @Action({ rawError: true })
  public getValidationStatus(phoneNumber: string) {
    this.setLoading(true)

    this.presenter
      .getValidationStatus(phoneNumber)
      .then(res => {
        this.setValidateData(res)
      })
      .catch(error => {
        if (error.status === 422 && error.data.error_id === 400) {
          this.setValidateData(new Validation(true, false, false))
        } else if (error.status === 422 && error.data.error_id === 444) {
          this.setValidateData(new Validation(false, true, false))
        } else {
          Vue.notify({
            title: 'Validate donation status failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public submitDonation(form: {
    senderName: string
    senderPhone: string
    address: string
    city: string
    district: string
    totalWeight: number
    packageType: PackageType
  }) {
    this.setLoading(true)

    this.presenter
      .submitDonation(
        new CampaignRamadhanRequest(
          form.senderName,
          form.senderPhone,
          form.address,
          form.city,
          form.district,
          form.totalWeight,
          form.packageType
        )
      )
      .then(res => {
        this.setOrderId(res)
        this.setValidateData(new Validation(false, false, true))
      })
      .catch(error => {
        if (error.status === 422 && error.data.error_id === 400) {
          this.setValidateData(new Validation(true, false, false))
        } else if (error.status === 422 && error.data.error_id === 444) {
          this.setValidateData(new Validation(false, true, false))
        } else {
          Vue.notify({
            title: 'Submit donation failed',
            text:
              error.status === 400 || error.status === 422
                ? error.error.message.en
                : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public resetValidateData() {
    this.validateData = new Validation(false, false, false)
  }

  @Mutation
  private setValidateData(validate: Validation) {
    this.validateData = validate
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setOrderId(val: number) {
    this.orderId = val
  }
}

export default getModule(CampaignRamadhanController)
