














import { Component, Vue, Prop } from 'vue-property-decorator'
import BackIcon from '@/app/ui/assets/CampaignRamadhan/back_icon.vue'

@Component({
  components: {
    BackIcon,
  },
})
export default class Header extends Vue {
  @Prop({ default: false }) private navToHome!: boolean

  private handleBackNav() {
    this.$router.go(-1)
  }

  get mobileHomePageUrl() {
    return process.env.VUE_APP_MOBILE_HOME_PAGE_URL
  }
}
