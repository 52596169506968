import { SubmitDonationRequestInterface } from '@/data/payload/contracts/CamapignRamadhanRequest'
import { PackageType } from '@/domain/entities/CampaignRamadhan'

export class CampaignRamadhanRequest implements SubmitDonationRequestInterface {
  private senderName: string
  private senderPhone: string
  private address: string
  private city: string
  private district: string
  private totalWeight: number
  private packageType: PackageType

  constructor(
    senderName: string,
    senderPhone: string,
    address: string,
    city: string,
    district: string,
    totalWeight: number,
    packageType: PackageType
  ) {
    this.senderName = senderName
    this.senderPhone = senderPhone
    this.address = address
    this.city = city
    this.district = district
    this.totalWeight = totalWeight
    this.packageType = packageType
  }

  public toPayload() {
    const data = {
      sender_name: this.senderName,
      sender_phone: this.senderPhone,
      address: this.address,
      city: this.city,
      district: this.district,
      package_type: {
        books: this.packageType.books,
        toys: this.packageType.toys,
        clothes: this.packageType.clothes,
        rice: this.packageType.rice,
      },
      total_weight: this.totalWeight,
    }

    return JSON.stringify({
      ...data,
    })
  }
}
