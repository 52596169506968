




















import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import CloseIcon from '@/app/ui/assets/CampaignRamadhan/close_icon.vue'

@Component({
  components: {
    CloseIcon,
    Button,
  },
})
export default class Modal extends Vue {
  @Prop({ default: false }) private visible!: boolean

  @Watch('visible')
  onVisibleChanged(val: boolean) {
    this.isVisible = val
  }
  isVisible = this.visible

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private closeModal() {
    this.$emit('handle-close-modal')
  }
}
